<template>
  <div>
    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_SkegeeAlumniCollection_V2.jpg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>

      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <p
              class="lead-heading text-center mb-1"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 700;
                font-size: 3em;
                color: black;
              "
            >
              Skegee Alumni Weekend Line-Up
            </p>
          </div>
          <hr />
          <div class="col-md-12">
            <div class="download-content">
              <p
                class="lead-heading mb-5 text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 22px;
                "
              ></p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-1 col-lg-1 align-items-center"></div>
        <div class="col-md-2 col-lg-2 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-3"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 300;
              font-size: 22px;
            "
          >
            07 <br />
            February
          </p>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a
            href="https://myle.com/skegee-alumni-meet-and-greet-karaoke-edition"
            target="_self"
          >
            <p
              class="text-center lead-heading ml-3 mb-0"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 24px;
                color: #ac1e2e;
              "
            >
              Skegee Alumni Meet & Greet: Karaoke Edition
            </p>
            <p
              class="text-center lead-heading ml-3 mb-3"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 300;
                font-size: 18px;
                color: #ac1e2e;
              "
            >
              Friday February 07, 2025 <br />
              08:00 PM - 12:00 AM
            </p>
            <div class="section-heading mb-2">
              <div class="text-center">
                <a
                  href="https://myle.com/skegee-alumni-meet-and-greet-karaoke-edition"
                  class="btn solid-btn-crimson mb-1 text-center"
                  target="_self"
                  >Get Tickets
                </a>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a
            href="https://myle.com/skegee-alumni-meet-and-greet-karaoke-edition"
            target="_self"
          >
            <div class="justify-content-center d-flex">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_SkegeeAlumniCollection_V2.jpg"
                alt="Saturday Event"
                class="w-100 img-fluid"
              />
            </div>
          </a>
        </div>

        <div class="col-md-1 col-lg-1 align-items-center"></div>
      </div>

      <br />
      <br />

      <div class="row">
        <div class="col-md-1 col-lg-1 align-items-center"></div>
        <div class="col-md-2 col-lg-2 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-3"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 300;
              font-size: 22px;
            "
          >
            08 <br />
            February
          </p>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a href="https://myle.com/skegee-alumni-brunch" target="_self">
            <p
              class="text-center lead-heading ml-3 mb-0"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 24px;
                color: #ac1e2e;
              "
            >
              Skegee Alumni Brunch
            </p>
            <p
              class="text-center lead-heading ml-3 mb-3"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 300;
                font-size: 18px;
                color: #ac1e2e;
              "
            >
              Saturday February 08, 2025 <br />
              10:00 AM - 01:00 PM
            </p>
            <div class="section-heading mb-2">
              <div class="text-center">
                <a
                  href="https://myle.com/skegee-alumni-brunch"
                  class="btn solid-btn-crimson mb-1 text-center"
                  target="_self"
                  >Get Tickets
                </a>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a href="https://myle.com/skegee-alumni-brunch" target="_self">
            <div class="justify-content-center d-flex">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_SkegeeAlumniCollection_V2.jpg"
                alt="Saturday Event"
                class="w-100 img-fluid"
              />
            </div>
          </a>
        </div>

        <div class="col-md-1 col-lg-1 align-items-center"></div>
      </div>

      <br />
      <br />

      <div class="row">
        <div class="col-md-1 col-lg-1 align-items-center"></div>
        <div class="col-md-2 col-lg-2 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-3"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 300;
              font-size: 22px;
            "
          >
            08 <br />
            February
          </p>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a
            href="https://myle.com/skegee-all-black-alumni-party"
            target="_self"
          >
            <p
              class="text-center lead-heading ml-3 mb-0"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 24px;
                color: #ac1e2e;
              "
            >
              Skegee All Black Alumni Party
            </p>
            <p
              class="text-center lead-heading ml-3 mb-3"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 300;
                font-size: 18px;
                color: #ac1e2e;
              "
            >
              Saturday February 08, 2025 <br />
              09:00 PM - 01:00 AM
            </p>
            <div class="section-heading mb-2">
              <div class="text-center">
                <a
                  href="https://myle.com/skegee-all-black-alumni-party"
                  class="btn solid-btn-crimson mb-1 text-center"
                  target="_self"
                  >Get Tickets
                </a>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a
            href="https://myle.com/skegee-all-black-alumni-party"
            target="_self"
          >
            <div class="justify-content-center d-flex">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_SkegeeAlumniCollection_V2.jpg"
                alt="Saturday Event"
                class="w-100 img-fluid"
              />
            </div>
          </a>
        </div>

        <div class="col-md-1 col-lg-1 align-items-center"></div>
      </div>

      <br />
      <br />

      <div class="row">
        <div class="col-md-1 col-lg-1 align-items-center"></div>
        <div class="col-md-2 col-lg-2 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-3"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 300;
              font-size: 22px;
            "
          >
            09 <br />
            February
          </p>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a
            href="https://myle.com/skegee-alumni-farewell-soulful-sunday"
            target="_self"
          >
            <p
              class="text-center lead-heading ml-3 mb-0"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 24px;
                color: #ac1e2e;
              "
            >
              Skegee Alumni Farewell: Soulful Sunday
            </p>
            <p
              class="text-center lead-heading ml-3 mb-3"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 300;
                font-size: 18px;
                color: #ac1e2e;
              "
            >
              Sunday February 09, 2025 <br />
              11:00 AM - 04:00 PM
            </p>
            <div class="section-heading mb-2">
              <div class="text-center">
                <a
                  href="https://myle.com/skegee-alumni-farewell-soulful-sunday"
                  class="btn solid-btn-crimson mb-1 text-center"
                  target="_self"
                  >Get Tickets
                </a>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a
            href="https://myle.com/skegee-alumni-farewell-soulful-sunday"
            target="_self"
          >
            <div class="justify-content-center d-flex">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_SkegeeAlumniCollection_V2.jpg"
                alt="Saturday Event"
                class="w-100 img-fluid"
              />
            </div>
          </a>
        </div>

        <div class="col-md-1 col-lg-1 align-items-center"></div>
      </div>

      <br />

      <div
        class="row w-100 m-0 promo-section MYLE-green"
        style="background: #ac1e2e"
      >
        <div class="col-lg-12 col-md-12">
          <div class="mt-5">
            <div class="section-heading mb-5">
              <h2 class="text-center text-white">
                DOWNLOAD MYLE TODAY TO GET STARTED
              </h2>
              <div
                class="download-btn mt-4 text-center animate__animated animate__fadeIn"
              >
                <a
                  href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                  class="btn google-play-btn mr-3"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><span class="ti-apple"></span> App Store</a
                >
                <a
                  href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                  class="btn google-play-btn mr-3"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><span class="ti-android"></span> Google Play</a
                >
                <a
                  href="https://web.myle.com"
                  class="btn google-play-btn"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><span class="ti-world"></span> MYLE Web</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 
        <div
          class="row align-items-center justify-content-between test"
          style="margin: auto; margin-top: 20px"
        >
          <div class="col-lg-3 col-md-3">
            <img
              class="align-middle"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/kappa_mocks.png"
              style="width: 100%; max-width: 100%; height: auto"
            />
          </div>
          <div class="col-lg-9 col-md-9">
            <p
              class="mt-0 mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 700;
                font-size: 24px;
                background-color: #ac1e2e;
                color: white;
              "
            >
              Get ready for The 86th Kappa Konclave with MYLE
            </p>
  
            <p
              class="mb-2 ml-4"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 18px;
              "
            >
              • Find all of the “Konclave on the Bay” events in MYLE
              <br />
              • Buy your event tickets with MYLE
              <br />
              • Keep all of your digital tickets in MYLE for easy and fast
              check-in
              <br />
              • Help the local chapter earn referral points – use referral code
              “TampaAlumniChapter” during account set-up
              <br />
              • Receive important updates before and during the event
              <br />
              • Get around Tampa seamlessly using the MYLE app for transportation
              options including Waze/Apple Maps/Google Maps/Uber and Lyft –
              integrated into MYLE mobile.
              <br />
              • Find other fun things to do in Tampa or on the way!
              <br />
            </p>
          </div>
        </div> -->

      <!-- <div class="row">
              <div class="col-md-6 col-lg-6 align-items-center">
                <a
                  href=" https://myle.com/for-the-kings-juneteenth-father-day-brunch"
                  target="_blank" rel="noopener noreferrer"
                >
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/the-art-of-brunch.png"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
      
                  <p
                    class="text-center lead-heading ml-3 mb-0"
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 500;
                      font-size: 18px;
                    "
                  >
                    For The Kings | Juneteenth + Father's Day Brunch
                  </p>
                  <p
                    class="text-center lead-heading ml-3 mb-3"
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 300;
                      font-size: 16px;
                    "
                  >
                    Sun, Jun 19, 12:00 PM
                  </p>
                </a>
              </div>
            </div> -->
    </div>

    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";

// defining component state
export default {
  name: "newyear",
  data() {
    return {};
  },
  components: {
    NavBar,
    SiteFooter,
  },

  // methods: {},
  // created() {},
  // mounted() {},
  // computed: {},

  metaInfo: {
    title: "MYLE - Skegee Alumni Collection",

    meta: [
      {
        name: "description",
        content: "MYLE - Skegee Alumni Collection",
      },

      {
        name: "keywords",
        content: "MYLE - Skegee Alumni Collection",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/skegee-alumni-collection",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}
</style>
