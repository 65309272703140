<template>
  <section
    class="client-section"
    v-bind:class="isGray ? 'gray-light-bg' : 'white-bg'"
  >
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-12">
          <carousel
            :items="1"
            :nav="false"
            :autoplay="true"
            :dots="false"
            :loop="true"
            :margin="15"
            slideTransition="linear"
            :autoplayTimeout="4000"
            :autoplaySpeed="false"
            :smartSpeed="6000"
            :responsive="{
              0: { items: 2 },
              500: { items: 3 },
              600: { items: 4 },
              800: { items: 5 },
              1200: { items: 6 },
              1400: { items: 7 },
              1600: { items: 8 },
              1800: { items: 9 },
              2000: { items: 10 },
              2200: { items: 11 },
              2400: { items: 12 },
              2600: { items: 13 },
              2800: { items: 14 },
              3000: { items: 15 },
              3200: { items: 16 },
              3400: { items: 17 },
              3600: { items: 18 },
            }"
            class="owl-theme clients-carousel dot-indicator owl-loaded owl-drag"
          >
            <div class="item single-client">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/new_saints_icons/icon_1.png"
                alt="client logo"
                class="client-img"
              />
            </div>
            <div class="item single-client">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/new_saints_icons/icon_2.png"
                alt="client logo"
                class="client-img"
              />
            </div>
            <div class="item single-client">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/new_saints_icons/icon_3.png"
                alt="client logo"
                class="client-img"
              />
            </div>
            <div class="item single-client">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/new_saints_icons/icon_4.png"
                alt="client logo"
                class="client-img"
              />
            </div>
            <div class="item single-client">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/new_saints_icons/icon_5.png"
                alt="client logo"
                class="client-img"
              />
            </div>
            <div class="item single-client">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/new_saints_icons/icon_6.png"
                alt="client logo"
                class="client-img"
              />
            </div>

            <div class="item single-client">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/new_saints_icons/icon_7.png"
                alt="client logo"
                class="client-img"
              />
            </div>

            <div class="item single-client">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/new_saints_icons/icon_8.png"
                alt="client logo"
                class="client-img"
              />
            </div>

            <div class="item single-client">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/new_saints_icons/icon_9.png"
                alt="client logo"
                class="client-img"
              />
            </div>

            <div class="item single-client">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/new_saints_icons/icon_10.png"
                alt="client logo"
                class="client-img"
              />
            </div>

            <div class="item single-client">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/new_saints_icons/icon_11.png"
                alt="client logo"
                class="client-img"
              />
            </div>

            <div class="item single-client">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/new_saints_icons/icon_12.png"
                alt="client logo"
                class="client-img"
              />
            </div>

            <div class="item single-client">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/new_saints_icons/icon_13.png"
                alt="client logo"
                class="client-img"
              />
            </div>

            <div class="item single-client">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/new_saints_icons/icon_14.png"
                alt="client logo"
                class="client-img"
              />
            </div>

            <div class="item single-client">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/new_saints_icons/icon_15.png"
                alt="client logo"
                class="client-img"
              />
            </div>

            <div class="item single-client">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/new_saints_icons/icon_16.png"
                alt="client logo"
                class="client-img"
              />
            </div>

            <div class="item single-client">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/new_saints_icons/icon_17.png"
                alt="client logo"
                class="client-img"
              />
            </div>

            <div class="item single-client">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/new_saints_icons/icon_18.png"
                alt="client logo"
                class="client-img"
              />
            </div>
          </carousel>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import carousel from "vue-owl-carousel";
export default {
  name: "SaintsSponsors",
  components: { carousel },
  props: {
    isGray: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
