<template>
  <div>
    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->
      <div class="row w-100 m-0 promo-section" style="background: #2c1323">
        <div class="col-lg-12 col-md-12 mt-5 m-0 p-0">
          <div class="mt-5 mb-0 p-0 mt-0 mb-0">
            <div class="text-center p-0 mt-5 mb-0">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_faqs_MarriottBonvoyHouseVisa_V1.png"
                style="width: 80%"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between mb-0 lessSideMargin mt-5"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <p
            class="lead-heading mb-1"
            style="
              font-family: 'RegulatorNova-Bold';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            Will I receive an email confirmation?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'RegulatorNova-Light';
              font-weight: 700;
              font-size: 1.3em;
              color: #000000;
            "
          >
            Yes, an email confirmation will have been automatically sent to you
            upon completing your registration. Please email
            <a style="color: #000000" href="mailto:marriottevents@travelhq.com">
              MarriottEvents@travelhq.com</a
            >
            if you’re having trouble accessing your confirmation.
          </p>

          <p
            class="lead-heading mb-1 mt-5"
            style="
              font-family: 'RegulatorNova-Bold';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            Are guests under the age of 21 permitted to attend?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'RegulatorNova-Light';
              font-weight: 700;
              font-size: 1.3em;
              color: #000000;
            "
          >
            No, please be advised that all guests are required to be at least 21
            years old to enter the event. All guests are subject to being asked
            to present their ID to verify their age at the entrance and within
            the venue.
          </p>

          <p
            class="lead-heading mb-1 mt-5"
            style="
              font-family: 'RegulatorNova-Bold';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            Will there be a dress code for the event?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'RegulatorNova-Light';
              font-weight: 700;
              font-size: 1.3em;
              color: #000000;
            "
          >
            The dress code is cocktail attire, inspired by the 1920s. See below
            for the dresscode moodboard. Large bags and bookbags are prohibited.
          </p>
        </div>
      </div>

      <img
        src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_faqs_fashioon_MarriottBonvoyHouseVisa_V1.png"
        style="width: 100%"
      />

      <div
        class="row align-items-center justify-content-between mb-0 lessSideMargin"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <p
            class="lead-heading mb-1"
            style="
              font-family: 'RegulatorNova-Bold';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            Will there be dietary accommodations?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'RegulatorNova-Light';
              font-weight: 700;
              font-size: 1.3em;
              color: #000000;
            "
          >
            Due to the size of the event, specific dietary requests cannot be
            accommodated.
          </p>

          <p
            class="lead-heading mb-1 mt-5"
            style="
              font-family: 'RegulatorNova-Bold';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            I can no longer attend, can I give my spot to someone else?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'RegulatorNova-Light';
              font-weight: 700;
              font-size: 1.3em;
              color: #000000;
            "
          >
            If you redeemed this experience through Marriott Bonvoy Moments,
            your points are not eligible for a refund. You may gift your package
            to friends or family. If you are no longer able to attend this event
            or need to update the attendee names, please contact us at least 48
            hours prior to the event at
            <a style="color: #000000" href="mailto:marriottevents@travelhq.com">
              MarriottEvents@travelhq.com</a
            >.
          </p>

          <p
            class="lead-heading mb-1 mt-5"
            style="
              font-family: 'RegulatorNova-Bold';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            My original guest cannot attend, can I bring someone else instead?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'RegulatorNova-Light';
              font-weight: 700;
              font-size: 1.3em;
              color: #000000;
            "
          >
            If your original guest is no longer able to attend, reach out to
            <a style="color: #000000" href="mailto:marriottevents@travelhq.com">
              MarriottEvents@travelhq.com</a
            >
            with the contact information for your new guest.
          </p>

          <p
            class="lead-heading mb-1 mt-5"
            style="
              font-family: 'RegulatorNova-Bold';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            I redeemed this as a Marriott Bonvoy Moment. Can I update my guest
            information in my Moments Account?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'RegulatorNova-Light';
              font-weight: 700;
              font-size: 1.3em;
              color: #000000;
            "
          >
            All attendee information should be submitted through this
            registration page. Any changes made in your Moments Account may not
            be reflected at event check in.
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'RegulatorNova-Bold';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            For any other questions, please contact
            <a style="color: #000000" href="mailto:marriottevents@travelhq.com">
              MarriottEvents@travelhq.com</a
            >.
          </p>
        </div>
      </div>
      <br />
    </div>
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../../views/commons/Navbar12";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      reserveFormAccept: false,
      tkList: {
        // tk_rJtGVRCIkpsvNvy: {
        //   messages: [
        //     "Ticket Price: $15",
        //     "Processing fee of $1.19",
        //     "Limited Time Offer",
        //     // "In Ticket price, $1.28 Fee included",
        //   ],
        // },
        tk_ZXJDjJ3rKdR9wnT: {
          messages: [
            "Ticket Price: $20",
            "Processing fee of $1.38",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
        tk_tQHtXbD8qjRbUlq: {
          messages: [
            "Ticket Price: $50",
            "Processing fee of $2.55",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
        tk_KL1Qk8jLXTDphGW: {
          messages: [
            "Ticket Price: $25",
            "Processing fee of $1.58",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
      },
      priceIds: {
        // tk_rJtGVRCIkpsvNvy: "price_1KGWsBD0vTZ4QB9c9P53fBs2",
        tk_ZXJDjJ3rKdR9wnT: "price_1KWAVZD0vTZ4QB9cX2roPVIm",
        tk_tQHtXbD8qjRbUlq: "price_1KWAVaD0vTZ4QB9cpiPzUJSd",
        tk_KL1Qk8jLXTDphGW: "price_1KWAVbD0vTZ4QB9cXs1RQlU6",
      },
      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
  },
  methods: {
    changeFavicon(url) {
      console.log("warda", url);
      var link = document.querySelectorAll("link[rel~='icon']");
      if (link && link.length) {
        link.forEach((r) => {
          r.remove();
        });
      }
      link = null;
      var applink = document.querySelectorAll("link[rel~='apple-touch-icon']");
      if (applink && applink.length) {
        applink.forEach((r) => {
          r.remove();
        });
      }
      applink = null;

      var mapplink = document.querySelectorAll("link[rel~='mask-icon']");
      if (mapplink && mapplink.length) {
        mapplink.forEach((r) => {
          r.remove();
        });
      }

      mapplink = null;

      setTimeout(() => {
        const mapplink1 = document.createElement("link");
        mapplink1.rel = "mask-icon";
        mapplink1.color = "#5bbad5";
        mapplink1.href = url + "safari-pinned-tab.svg?v=2";
        document.head.appendChild(mapplink1);

        const applink1 = document.createElement("link");
        applink1.rel = "apple-touch-icon";
        applink1.sizes = "180x180";
        applink1.href = url + "apple-touch-icon.png?v=2";
        document.head.appendChild(applink1);

        const applink12 = document.createElement("link");
        applink12.rel = "apple-touch-icon";
        applink12.sizes = "152x152";
        applink12.href = url + "apple-touch-icon.png?v=2";
        document.head.appendChild(applink12);

        const link1 = document.createElement("link");
        link1.rel = "icon";
        link1.sizes = "16x16";
        link1.href = url + "favicon-16x16.png?v=2";
        document.head.appendChild(link1);

        const link96 = document.createElement("link");
        link96.rel = "icon";
        link96.sizes = "96x96";
        link96.href = url + "favicon-96x96.png?v=2";
        document.head.appendChild(link96);

        const link192 = document.createElement("link");
        link192.rel = "icon";
        link192.sizes = "192x192";
        link192.href = url + "android-icon-192x192.png?v=2";
        document.head.appendChild(link192);

        const link180 = document.createElement("link");
        link180.rel = "apple-touch-icon";
        link180.sizes = "180x180";
        link180.href = url + "apple-icon-180x180.png?v=2";
        document.head.appendChild(link180);

        const link152 = document.createElement("link");
        link152.rel = "apple-touch-icon";
        link152.sizes = "152x152";
        link152.href = url + "apple-icon-152x152.png?v=2";
        document.head.appendChild(link152);

        const link144 = document.createElement("link");
        link144.rel = "apple-touch-icon";
        link144.sizes = "144x144";
        link144.href = url + "apple-icon-144x144.png?v=2";
        document.head.appendChild(link144);

        const link120 = document.createElement("link");
        link120.rel = "apple-touch-icon";
        link120.sizes = "120x120";
        link120.href = url + "apple-icon-120x120.png?v=2";
        document.head.appendChild(link120);

        const link114 = document.createElement("link");
        link114.rel = "apple-touch-icon";
        link114.sizes = "114x114";
        link114.href = url + "apple-icon-114x114.png?v=2";
        document.head.appendChild(link114);

        const link76 = document.createElement("link");
        link76.rel = "apple-touch-icon";
        link76.sizes = "76x76";
        link76.href = url + "apple-icon-76x76.png?v=2";
        document.head.appendChild(link76);

        const link72 = document.createElement("link");
        link72.rel = "apple-touch-icon";
        link72.sizes = "72x72";
        link72.href = url + "apple-icon-72x72.png?v=2";
        document.head.appendChild(link72);

        const link60 = document.createElement("link");
        link60.rel = "apple-touch-icon";
        link60.sizes = "60x60";
        link60.href = url + "apple-icon-60x60.png?v=2";
        document.head.appendChild(link60);

        const link57 = document.createElement("link");
        link57.rel = "apple-touch-icon";
        link57.sizes = "57x57";
        link57.href = url + "apple-icon-57x57.png?v=2";
        document.head.appendChild(link57);

        const link2 = document.createElement("link");
        link2.rel = "icon";
        link2.sizes = "32x32";
        link2.href = url + "favicon-32x32.png?v=2";
        document.head.appendChild(link2);

        const link3 = document.createElement("link");
        link3.rel = "icon";
        link3.href = url + "favicon.ico?v=2";
        document.head.appendChild(link3);
      }, 300);
    },
  },
  mounted() {
    const r = window.location.origin;
    this.changeFavicon(r + "/img/icons8/");
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "Marriott Bonvoy House '25 FAQs",

    meta: [
      {
        name: "description",
        content: "Marriott Bonvoy House '25 FAQs",
      },

      {
        name: "keywords",
        content: "Marriott Bonvoy House '25 FAQs",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/marriott-bonvoy-house-25-for-m1-faqs",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

#customers {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  /* width: 100%; */
  height: auto;
}
#customers td,
#customers th {
  font-size: 1em;
  border: 1px solid #98bf21;
  padding: 3px 7px 2px 7px;
}
#customers th {
  font-size: 1.2em;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 4px;
  background-color: #a7c942;
  color: #ffffff;
}

.bolded {
  font-weight: bold;
}
</style>
