<template>
  <div style="background-color: #ffffff">
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />

    <!-- conifrm model -->
    <nu-modal ref="confirmmodal" title="">
      <confirmationPopup :showsuccess="showsuccess" @closemodal="closeModal" />
    </nu-modal>
    <!-- confrim model -->

    <nu-modal ref="modelwait" title="">
      <WaitlistSuccess style="height: 360px" />
    </nu-modal>
    <nu-modal ref="modalreg1" title="">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 mb-4">
            <div class="text-center">
              <h3
                class="w-auto d-inline-block"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 2rem;
                  color: black;
                "
              >
                Waiting List Form
              </h3>
            </div>
          </div>

          <div class="col-md-6">
            <label
              class="mt-2"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 500;
                font-size: 1.1em;
                color: black;
              "
            >
              Full Name
            </label>
            <input
              type="text"
              class="form-control mb-3"
              placeholder="Full Name"
              required
              v-model="fullname"
            />
          </div>

          <div class="col-md-6 mb-1">
            <label
              class="mt-2"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 500;
                font-size: 1.1em;
                color: black;
              "
            >
              Email Address
            </label>
            <input
              type="email"
              class="form-control mb-3"
              placeholder="Email"
              v-model="email"
              required
            />
            <span
              v-bind:class="{ 'op-0': !(istouched && !invalidemail) }"
              class="mb-3"
              style="color: red; font-size: 12px"
              >*Please provide valid email address</span
            >
          </div>

          <div class="col-md-12">
            <label
              class=""
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 500;
                font-size: 1.1em;
                color: black;
              "
              >How did you hear about this event?</label
            >
            <input
              type="text"
              class="form-control mb-3"
              placeholder=""
              v-model="hearAbout"
              required
            />
          </div>

          <div class="col-md-12">
            <label
              class=""
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 500;
                font-size: 1.1em;
                color: black;
              "
              >List Any Food Allergies?
            </label>
            <input
              type="text"
              class="form-control mb-3"
              placeholder=""
              v-model="foodAllergy"
              required
            />
          </div>

          <div class="col-md-6">
            <label
              class="mt-3"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 500;
                font-size: 1.1em;
                color: black;
              "
              >Social Media Handle (Optional)</label
            >
            <input
              type="text"
              class="form-control mb-3"
              placeholder="@mysocialhandle"
              v-model="hearAboutSocial"
              required
            />
          </div>

          <div class="col-md-6">
            <label
              class="mt-3"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 500;
                font-size: 1.1em;
                color: black;
              "
              >LinkedIn Page (Optional)</label
            >
            <input
              type="text"
              class="form-control mb-3"
              placeholder="Link or Handle"
              v-model="hearAboutLinkedIn"
              required
            />

            <!-- hearAboutSocial: this.hearAboutSocial,
            hearAboutLinkedIn: this.hearAboutLinkedIn, -->

            <!-- <select class="form-control" v-model="hearAboutSocial">
              <option
                required
                class="form-control"
                v-bind:key="count + 'b'"
                v-for="count in ['Social Media', 'LinkedIn']"
                :value="count"
              >
                {{ count }}
              </option>
            </select> -->
          </div>

          <div
            class="col-md-12 mt-4"
            style="
              font-family: 'nunito', sans-serif;
              font-weight: 500;
              font-size: 1.15em;
              color: black;
            "
          >
            <input type="checkbox" v-model="reserveFormAccept" />
            I understand that all sales are final. No refunds. I have read and
            agree with the MYLE
            <a
              href="https://myle.com/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              terms and conditions.</a
            >
          </div>

          <div class="col-md-12 mt-5 text-center">
            <button
              type="submit"
              @click="joinpaynowcustom()"
              class="btn solid-btn-Saints shimmer text-center"
              style="
                pointer-events: all;
                cursor: pointer;
                font-family: 'Montserrat', sans-serif;
                font-weight: 700;
                font-size: 1em;
                font-weight: bold;
              "
            >
              Join Waitlist
            </button>
          </div>
        </div>
      </div>
    </nu-modal>

    <nu-modal ref="modal">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2rem;
                    color: black;
                  "
                >
                  Reservation Form
                </h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  color: black;
                "
              >
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.1em;
                    color: black;
                  "
                  >Ticket Title:</b
                >
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p
                v-if="!redeemData && false"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  color: black;
                "
              >
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.1em;
                    color: black;
                  "
                  >Per Person Price:</b
                >
                ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p
                v-if="redeemData && false"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  color: black;
                "
              >
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.1em;
                    color: black;
                  "
                  >Per Person Price:</b
                >
                ${{ redeemData.price.toFixed(2) }}
              </p>
              <p
                v-if="!redeemData && false"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  color: black;
                "
              >
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.1em;
                    color: black;
                  "
                >
                  Total Price:
                </b>
                ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p
                v-if="redeemData && false"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  color: black;
                "
              >
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.1em;
                    color: black;
                  "
                >
                  Total Price:
                </b>
                ${{ ((redeemData.price || 0) * tickcount).toFixed(2) }}
              </p>
            </div>
            <div class="col-md-6">
              <label
                class="mt-2"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
              >
                Full Name
              </label>
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <label
                class="mt-2"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
              >
                Email Address
              </label>
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div class="col-md-6 mb-1" v-if="false">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
                >Number of Ticket(s)</label
              >
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div class="col-md-6" v-if="false">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
                >Age Range</label
              >
              <select class="form-control" v-model="ageRange">
                <option
                  class="form-control"
                  v-bind:key="ageval + 'b'"
                  v-for="ageval in ageRangesList"
                  :value="ageval"
                >
                  {{ ageval }}
                </option>
              </select>
            </div>

            <div class="col-md-12">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
                >How did you hear about this event?</label
              >
              <input
                type="text"
                class="form-control mb-3"
                placeholder=""
                v-model="hearAbout"
                required
              />
            </div>

            <div class="col-md-12">
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
                >List Any Food Allergies?
              </label>
              <input
                type="text"
                class="form-control mb-3"
                placeholder=""
                v-model="foodAllergy"
                required
              />
            </div>

            <div class="col-md-6">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
                >Social Media Handle (Optional)</label
              >
              <input
                type="text"
                class="form-control mb-3"
                placeholder="@mysocialhandle"
                v-model="hearAboutSocial"
                required
              />
            </div>

            <div class="col-md-6">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
                >LinkedIn Page (Optional)</label
              >
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Link or Handle"
                v-model="hearAboutLinkedIn"
                required
              />

              <!-- hearAboutSocial: this.hearAboutSocial,
              hearAboutLinkedIn: this.hearAboutLinkedIn, -->

              <!-- <select class="form-control" v-model="hearAboutSocial">
                <option
                  required
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in ['Social Media', 'LinkedIn']"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select> -->
            </div>

            <div
              class="col-md-6 mb-1"
              v-if="
                ((TicketsResult[selected] || {}).availableSeating || [])
                  .length > 0 &&
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode == 'tk_NzrhJLxmsLhNLaD'
              "
            >
              <label class="mt-3">Available Seating</label>
              <select class="form-control" v-model="availSeating">
                <option
                  class="form-control"
                  v-bind:key="seating.table"
                  v-for="seating in uniq(
                    (TicketsResult[selected] || {}).availableSeating
                  )"
                  :value="seating"
                >
                  {{ seating }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-1"
              v-if="
                ((TicketsResult[selected] || {}).availableSeating || [])
                  .length > 0 &&
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode !== 'tk_Zp9izBaReeI9K6t' &&
                TicketsResult[selected].ticketCode !== 'tk_NzrhJLxmsLhNLaD'
              "
            >
              <label class="mt-3">Available Seating</label>
              <select class="form-control" v-model="availSeating">
                <option
                  class="form-control"
                  v-bind:key="seating.alias"
                  v-for="seating in (TicketsResult[selected] || {})
                    .availableSeating"
                  :value="seating.alias"
                >
                  {{ seating.alias }}
                </option>
              </select>
            </div>

            <!-- <div
              class="col-md-12 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
                >Do you have a passcode?</label
              >

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Passcode"
              />
            </div> -->

            <!-- <div
              class="col-md-12 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn-Saints btn-sm my-auto shimmer"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 700;
                  font-size: 1em;
                  font-weight: bold;
                "
              >
                Verify Passcode
              </button>
            </div> -->

            <div
              class="col-md-12 mt-2"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode == 'tk_Zp9izBaReeI9K6t'
              "
            >
              <doublelist
                @listtopush="listtopush"
                v-if="
                  TicketsResult[selected] &&
                  TicketsResult[selected].availableSeating.length > 0
                "
                :list="(TicketsResult[selected] || {}).availableSeating"
                :limit="(TicketsResult[selected] || {}).allowedPerRequest || 0"
              />
            </div>

            <div
              class="col-md-12 mt-4"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 500;
                font-size: 1.15em;
                color: black;
              "
            >
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept
                "
                class="btn solid-btn-Saints m-auto mr-2 shimmer"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 700;
                  font-size: 1em;
                  font-weight: bold;
                "
              >
                <!-- (TicketsResult[selected] && TicketsResult[selected].discount
                    ? !redeemPriceId.trim().length
                    : false) || -->

                Reserve Now
              </button>
              <br />
              <br />
              <br />
              <hr />
              <p
                class="mt-5"
                style="
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                "
              >
                If tickets are Sold Out, Join our Waitlist for a chance to be
                invited to this exclusive event.
              </p>

              <button
                class="btn solid-btn-Saints shimmer text-center"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 700;
                  font-size: 1em;
                  font-weight: bold;
                "
                @click="openRegisterModal()"
              >
                JOIN WAITLIST
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div
      class="container-fluid p-0"
      style="overflow-x: hidden; background: #ffffff"
    >
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_JahriEvansSaintsAndStarsBigGameWeekendMasqueradeMixer_V1.png"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div
        class="row w-100 m-0 promo-section MYLE-lite-green"
        style="background: #baa168"
      >
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <!-- <h1
                class="text-center mb-0"
                style="
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 700;
                  font-size: 3em;
                  color: white;
                "
              >
                Jahri Evans Saints and Stars Big Game Weekend Masquerade Mixer
              </h1>

              <h1
                class="text-center mt-3"
                style="
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 700;
                  font-size: 18px;
                  color: white;
                "
              >
                Friday, February 07, 2025 <br />
                06:00 PM - 10:00 PM
              </h1> -->

              <h1
                class="text-center animate__animated animate__bounceIn"
                style="
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 700;
                  font-size: 16px;
                  color: white;
                "
              >
                Curated Vibes | Live Music | Masterfully Crafted Cocktails
              </h1>

              <h1
                class="text-center animate__animated animate__bounceIn"
                style="
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 500;
                  font-size: 16px;
                  color: white;
                "
              >
                It’s the ultimate celebration of style, glamour, and the
                electrifying spirit of NOLA.

                <!-- <a
                  href="https://maps.app.goo.gl/GHxSorgtY3ECrtSh8"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: white"
                >
                  Plates Restaurant
                  <br />
                  1051 Annunciation St, New Orleans, LA 70130
                </a> -->
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px; color: black"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="download-content">
              <p
                id="description"
                class="text-center mb-0 animate__animated animate__fadeIn"
                style="
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 500;
                  font-size: 1.6em;
                "
              >
                Kick off Super Bowl weekend in style with an exclusive
                Masquerade Mixer, where elegance, glamour, and excitement meets
                a weekend of specially curated events infused with the culture
                and heart of New Orleans!

                <br />
                <br />

                Hosted by NFL Legend Jahri Evans in partnership with the Saints
                and Louisiana Sports Hall of Fame, the Masquerade Mixer promises
                a night of unparalleled elegance, star-studded glamour, and
                irresistible allure. This luxurious evening will set the tone
                for an exciting weekend filled with unforgettable experiences,
                tantalizing cuisine, and an atmosphere brimming with excitement.

                <br />
                <br />

                <span style="font-weight: 700"
                  >Don’t miss your chance to be a part of this captivating
                  celebration!
                </span>
              </p>

              <div class="download-content mt-5" id="adown">
                <p
                  class=""
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 1.6em;
                    color: #0b3048;
                  "
                >
                  <button
                    class="btn solid-btn-Saints shimmer text-center animate__animated animate__heartBeat"
                    @click="openmodel()"
                    style="
                      pointer-events: all;
                      cursor: pointer;
                      margin: auto;
                      display: block;
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 700;
                      font-size: 1em;
                      font-weight: bold;
                    "
                  >
                    RSVP NOW
                  </button>
                </p>
              </div>

              <div class="row mt-5">
                <div class="col-md-12">
                  <p class="text-center">
                    <img
                      id="imagesection"
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/flyer_JahriEvansSaintsAndStarsBigGameWeekendMasqueradeMixer_V2.png"
                      style="border-radius: 1%; width: 80%; text-align: center"
                      class="text-center"
                    />
                  </p>
                </div>
              </div>

              <div id="anight">
                <p
                  class="mt-5 animate__animated animate__fadeIn"
                  style="
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                  "
                >
                  <span style="font-size: 1.4em; font-weight: 700">
                    A Night Like No Other
                  </span>

                  <br />
                  Join us for an unforgettable evening with NFL legends,
                  including Super Bowl Champion New Orleans Saints, socialites,
                  influencers, and New Orleans’ elite in an exclusive, curated
                  setting.

                  <br />
                  <span style="font-weight: 700; margin-left: 10px">• </span>
                  Red Carpet Entrance
                  <br />
                  <span style="font-weight: 700; margin-left: 10px">• </span>
                  Complimentary Valet
                  <br />
                  <span style="font-weight: 700; margin-left: 10px">• </span>
                  Cocktail Reception
                  <br />
                  <span style="font-weight: 700; margin-left: 10px">• </span>
                  Specialty Cocktails
                  <br />
                  <span style="font-weight: 700; margin-left: 10px">• </span>
                  Silent Auction of Curated Sports Memorabilia
                  <br />
                  <span style="font-weight: 700; margin-left: 10px">• </span>
                  Passed Hors D'ourves
                  <br />
                  <span style="font-weight: 700; margin-left: 10px">• </span>
                  Action Stations Of Louisiana Cuisine
                  <br />
                  <span style="font-weight: 700; margin-left: 10px">• </span>
                  Live Music
                </p>

                <p
                  class="mt-0 mb-5"
                  id="adress"
                  style="
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 400;
                    font-size: 1.2em;
                  "
                >
                  <span style="font-size: 1.4em; font-weight: 700">
                    Dress to Impress
                  </span>

                  <br />

                  <span style="font-weight: 500">
                    This masquerade experience calls for your finest attire and
                    a mysterious mask. Step into a world of elegance and
                    intrigue where the spotlight is on you.
                  </span>
                </p>

                <div class="row mt-0">
                  <div class="col-md-12">
                    <p class="text-center">
                      <img
                        id="imagesection"
                        src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_strip_1_JahriEvansSaintsAndStarsBigGameWeekendMasqueradeMixer.png"
                        style="
                          border-radius: 0px;
                          width: 100%;
                          text-align: center;
                        "
                        class="text-center"
                      />
                    </p>
                  </div>
                </div>

                <div class="download-content mt-3" id="adown">
                  <p
                    class=""
                    style="
                      font-family: 'nunito', sans-serif;
                      font-size: 1.6em;
                      color: #0b3048;
                    "
                  >
                    <button
                      class="btn solid-btn-Saints shimmer text-center animate__animated animate__heartBeat"
                      @click="openmodel()"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        margin: auto;
                        display: block;
                        font-family: 'Montserrat', sans-serif;
                        font-weight: 700;
                        font-size: 1em;
                        font-weight: bold;
                      "
                    >
                      RSVP NOW
                    </button>
                  </p>
                </div>

                <p
                  id="ainvi"
                  class="mt-0 mb-5"
                  style="
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 400;
                    font-size: 1.2em;
                  "
                >
                  <span style="font-size: 1.4em; font-weight: 700">
                    Secure Your Invitation
                  </span>

                  <br />

                  <span style="font-weight: 500">
                    This exclusive, invitation-only event has limited spots.
                    RSVP now to secure your place at this unforgettable New
                    Orleans celebration.
                  </span>
                </p>

                <p
                  class="mt-0 mb-5"
                  style="
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 400;
                    font-size: 1.2em;
                  "
                >
                  <span style="font-size: 1.4em; font-weight: 700">
                    Don't Miss This Night
                  </span>

                  <br />

                  <span style="font-weight: 500">
                    From the red carpet to the final toast, the Masquerade Mixer
                    is a night of style, glamour, and NOLA spirit you won’t want
                    to miss.
                  </span>
                </p>
              </div>

              <div class="download-content" id="adown">
                <p
                  class=""
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 1.6em;
                    color: #0b3048;
                  "
                >
                  <button
                    class="btn solid-btn-Saints shimmer text-center animate__animated animate__heartBeat"
                    @click="openmodel()"
                    style="
                      pointer-events: all;
                      cursor: pointer;
                      margin: auto;
                      display: block;
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 700;
                      font-size: 1em;
                      font-weight: bold;
                    "
                  >
                    RSVP NOW
                  </button>
                </p>

                <br />
              </div>

              <!-- <hr v-if="activeWaitList" /> -->
              <!-- ////////// WAIT LIST SECTION ////////// -->
              <!-- <div class="text-center mt-4" v-if="activeWaitList">
                <p
                  class="w-auto d-inline-block mb-0"
                  style="
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 700;
                    font-size: 2em;
                  "
                >
                  Waitlist
                </p>
                <p
                  class="mt-0"
                  style="
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                  "
                >
                  Reserve a spot on our waitlist.
                </p>

                <button
                  class="btn solid-btn-Saints shimmer text-center"
                  style="
                    pointer-events: all;
                    cursor: pointer;
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 700;
                    font-size: 1em;
                    font-weight: bold;
                  "
                  @click="openRegisterModal()"
                >
                  JOIN WAITLIST
                </button>
              </div> -->
              <!-- ////////////////////////////// -->

              <!-- <br />
              <br /> -->
              <p
                class="mt-0 mb-0 text-center animate__animated animate__rubberBand"
                style="
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                "
              >
                <span style="font-weight: 700">
                  ✨ See you on the red carpet! ✨
                </span>
              </p>

              <!-- <div class="row mt-3">
                <div class="col-md-12">
                  <p class="text-center">
                    <img
                      id="imagesection1"
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_strip_1_JahriEvansSaintsAndStarsBigGameWeekendMasqueradeMixer.png"
                      style="border-radius: 1%; width: 100%; text-align: center"
                      class="text-center"
                    />
                  </p>
                </div>
              </div> -->

              <div class="row mt-3">
                <div class="col-md-12">
                  <p class="text-center">
                    <img
                      id="imagesectio2"
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_strip_2_JahriEvansSaintsAndStarsBigGameWeekendMasqueradeMixer.png"
                      style="border-radius: 1%; width: 100%; text-align: center"
                      class="text-center"
                    />
                  </p>
                </div>
              </div>

              <p
                class="mt-0 mb-5 text-center animate__animated animate__fadeIn"
                style="
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 400;
                  font-size: 1.2em;
                "
              >
                <span style="font-weight: 500">
                  <span style="font-weight: 700">
                    Jahri Evans Saints and Stars Big Game Weekend</span
                  >
                  is all about giving back – locally and globally! A portion of
                  proceeds supports
                  <span style="font-weight: 700"
                    >The International People’s Storehouse (T.I.P.S.)</span
                  >, and
                  <span style="font-weight: 700"
                    >The Delvin Breaux Sr. Foundation.</span
                  >
                </span>
              </p>

              <div class="row mt-0 mb-5">
                <div class="col-md-1"></div>
                <div class="col-md-4">
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_TIPS_Logo_V3.png"
                    style="border-radius: 3%; width: 35%"
                    class="text-center"
                  />

                  <span
                    style="
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 500;
                      font-size: 1.1em;
                    "
                  >
                    T.I.P.S., a registered 501(c)(3) nonprofit, supports
                    communities facing poverty, crime, and social inequality. It
                    provides essential resources like food, hygiene items,
                    funding, gifts for children, and trauma-informed care. The
                    organization hosts free resource fairs, distributing health
                    kits, school supplies, toys, clothing, and household goods
                    to over 3,000 individuals in Philadelphia and nearby areas.
                    T.I.P.S. also offers underserved families opportunities to
                    attend live sports events, fostering entertainment and joy.
                  </span>
                </div>

                <div class="col-md-2 text-center"></div>

                <div class="col-md-4">
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/new_saints_icons/icon_5.png"
                    style="border-radius: 3%; width: 35%"
                    class=""
                  />

                  <span
                    style="
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 500;
                      font-size: 1.1em;
                    "
                  >
                    The Delvin Breaux Sr. Foundation supports youth impacted by
                    Trauma, Abuse, and Neglect (T.A.N.) through Mental Health
                    Boot Camps in partnership with Dillard University’s Center
                    for Racial Justice. Prioritizing mental well-being over
                    traditional football training, the foundation serves New
                    Orleans and Louisiana communities by offering resources like
                    job programs, scholarships, financial literacy, mentorship,
                    nutrition advice, and counseling to help kids thrive in life
                    and sports.
                  </span>
                </div>
                <div class="col-md-1 text-center"></div>
              </div>

              <div class="mt-5 text-center">
                <div class="text-center">
                  <h3
                    class="mt-5 w-auto d-inline-block"
                    style="
                      font-family: 'Montserrat', sans-serif;
                      font-size: 2.6em;
                      color: black;
                    "
                  >
                    Sponsors and Partners
                  </h3>
                </div>

                <SaintsSponsors />
              </div>

              <p
                class="mt-5 mb-5 text-center animate__animated animate__fadeIn"
                style="
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 400;
                  font-size: 1.2em;
                "
              >
                <span style="font-weight: 500">
                  <span style="font-weight: 700">
                    For information about becoming a sponsor, VIP packages or
                    general questions contact us at
                    <a href="mailto:hello@myle.com">
                      SaintsandStars@nkmconsulting.org</a
                    ></span
                  >
                </span>
              </p>

              <div class="col-md-12 text-center">
                <a
                  href="https://myle.com/saints-and-stars-big-game-weekend-watch-party"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: white"
                >
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/flyer_SaintsandStarsBigGameWeekendWatchParty_V5.jpg"
                    style="border-radius: 3%; width: 75%"
                    class="text-center"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <transition
      mode="out-in"
      appear
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <div
        class="row w-100 m-0 promo-section MYLE-lite-green"
        style="background: #baa168"
      >
        <div class="col-lg-12 col-md-12">
          <div class="mt-5">
            <div class="section-heading mb-5">
              <h2 class="text-center" style="color: white">
                Download today and try it for yourself
              </h2>
              <div
                class="download-btn mt-4 text-center animate__animated animate__fadeIn"
              >
                <a
                  href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                  class="btn google-play-btn mr-3"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><span class="ti-apple"></span> App Store</a
                >
                <a
                  href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                  class="btn google-play-btn mr-3"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><span class="ti-android"></span> Google Play</a
                >
                <a
                  href="https://web.myle.com"
                  class="btn google-play-btn"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><span class="ti-world"></span> MYLE Web</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
import SaintsSponsors from "../../views/commons/SaintsSponsors";
import confirmationPopup from "../../components/confirmationPopup";

import WaitlistSuccess from "./WaitlistSuccess/WaitlistSuccess.vue";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      showsuccess: false,
      reserveFormAcceptAboutSurveyOptIn: false,
      guestComing: "Yes",
      guestFirstName: "",
      guestLastName: "",
      guestEmail: "",
      guestContactPhone: "",
      qquestion: "",
      istouched: false,
      preicelessData: [],
      userFirstName: "",
      userLastName: "",
      usersEmail: "",
      userContactPhone: "",
      streetAddress: "",
      city: "",
      zip: "",
      state: "",
      question: "",

      iframeSrc: null,
      activeWaitList: false,
      ageRangesList: ["21-29", "30-39", "40-49", "50+"],
      ageRange: "21-29",
      selectionList: [],
      dict: [],
      availSeating: "",
      required_power_needs: "No",
      selectedSizes: "Extra Small - XS",
      sizes: [
        "Extra Small - XS",
        "Small - S",
        "Medium - M",
        "Large - L",
        "Extra Large - XL",
        "2 Extra Large - 2XL",
        "3 Extra Large - 3XL",
        "4 Extra Large - 4XL",
        "5 Extra Large - 5XL",
      ],
      individualsData: ["tk_EBsJ0X6RyewOEu8", "tk_PaNP4WFlZ2x6x28"],
      groupData: ["tk_EBsJ0X6RyewOEu8", "tk_PaNP4WFlZ2x6x28"],
      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        tk_EBsJ0X6RyewOEu8: {
          messages: ["Ticket Price: $0", "Processing fee of $0.0"],
        },
        tk_PaNP4WFlZ2x6x28: {
          messages: ["Ticket Price: $0", "Processing fee of $0.0"],
        },
      },

      priceIds: {
        tk_EBsJ0X6RyewOEu8: "price_1QllzCD0vTZ4QB9cDdu3O7wU",
        tk_PaNP4WFlZ2x6x28: "price_1QcvGyD0vTZ4QB9cvcu5f80O",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      hearAboutSocial: "",
      hearAboutLinkedIn: "",
      hearAbout: "",
      foodAllergy: "",
      tickcount: 1,
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
    // VueFlux,
    // FluxPreloader,
    // FluxControls,
    WaitlistSuccess,
    StripeCheckout,
    SaintsSponsors,
    confirmationPopup,
  },

  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isNumber1: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    listtopush(rr) {
      this.selectionList = [...rr];
    },
    joinpaynowcustom: function () {
      if (this.valCheck || !this.reserveFormAccept) {
        return;
      }
      this.isSubmitted = true;
      const vm = this;
      const payloadData = {
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
        userId: "",
        eventId: "SE6DsRyAoimkY6",
        origin: "WEB",
        tickets: [],
      };
      var tickObj = {
        priceId: "price_1QcvGyD0vTZ4QB9cvcu5f80O",
        ticketId: "tk_PaNP4WFlZ2x6x28",
        totalTickets: 1,
        additionalInfo: {},
        extraInfo: {
          hearAboutSocial: this.hearAboutSocial,
          hearAboutLinkedIn: this.hearAboutLinkedIn,
          hearAbout: this.hearAbout,
          foodAllergy: this.foodAllergy,
        },
      };

      payloadData.tickets = [tickObj];

      vm.isSubmitted = true;
      this.isLoading = true;

      axios
        .post("https://api.myle.com/api/addToWaitList", payloadData)
        .then((re) => {
          vm.action = "";
          vm.actionValue = "";
          if (re.data && re.data.Message) {
            vm.$refs.modalreg1.close();

            vm.$refs.modelwait.open();
            vm.fetchAllTickets();
          } else {
            vm.$toast.error("Session Id not Received", { timeout: 4000 });
          }
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
          vm.reserveFormAcceptAboutSurveyOptIn = false;
        })
        .finally(() => {
          vm.isSubmitted = false;

          vm.reserveFormAccept = false;
          vm.reserveFormAcceptAboutSurveyOptIn = false;
        });
    },

    closeModal() {
      this.showsuccess = false;
      this.$refs.confirmmodal.close();
    },

    openRegisterModal() {
      this.$refs.modal.close();
      this.guestComing = "No";
      this.guestFirstName = "";
      this.guestLastName = "";
      this.guestEmail = "";
      this.guestContactPhone = "";
      this.qquestion = "";
      this.istouched = false;
      this.userFirstName = "";
      this.userLastName = "";
      this.usersEmail = "";
      this.userContactPhone = "";
      this.streetAddress = "";
      this.city = "";
      this.zip = "";
      this.state = "";
      this.question = "";
      this.$refs.modalreg1.open();
    },
    uniq(list) {
      if (list && list.length > 0) {
        const uniqValues = {};
        list.forEach((v) => {
          uniqValues[v.table] = true;
        });

        return Object.keys(uniqValues);
      }

      return [];
    },
    datad1(listdata) {
      const seat = {
        1: 1200,
        2: 600,
        3: 400,
        4: 300,
        5: 240,
        6: 200,
        7: 171.4285714,
        8: 150,
        9: 133.33333,
        10: 120,
        11: 109.1,
      };
      const texts = [
        [1, 6, 7, 11, 12, 17, 18],
        [2, 5, 8, 10, 13, 16, 19],
        [3, 4, 9, 14, 15, 20],
      ];
      const distances = [0, 0, 20];
      const nodes = [];
      for (let y = 1; y <= 3; y++) {
        for (let i = 1; i <= 7; i++) {
          if (texts[y - 1][i - 1]) {
            nodes.push({
              x: 70 * i + distances[y - 1],
              y: 70 * y,
              val: seat[8],
              distance: i * 10,
              ydistance: y * 10,
              selected: false,
              text: texts[y - 1][i - 1],
              availableSeats: [],
            });
          }
        }
      }

      let recs = [];
      this.dict = {};
      listdata.forEach((r) => {
        recs = [...recs, ...r.availableSeating];
      });

      recs.forEach((r) => {
        const tableRecord = r.table.split("Table ")[1];
        const seatRecord = r.seat.split("Seat ")[1];

        if (!this.dict[tableRecord]) {
          this.dict[tableRecord] = [];
        }

        this.dict[tableRecord].push(Number(seatRecord - 1));
      });
      return nodes;
    },
    openmodel: function () {
      let idx = 0;
      this.selectionList = [];
      this.redeemData = null;
      this.redeemPriceId = "";
      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
        extraInfo: {},
      };

      payloadToPush.extraInfo["hearAboutSocial"] = this.hearAboutSocial;
      payloadToPush.extraInfo["hearAboutLinkedIn"] = this.hearAboutLinkedIn;
      payloadToPush.extraInfo["hearAbout"] = this.hearAbout;
      payloadToPush.extraInfo["foodAllergy"] = this.foodAllergy;

      const req =
        "https://myle-19701.uc.r.appspot.com/api/" +
        ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
          !this.redeemData) ||
        (this.redeemData && this.redeemData.price !== 0)
          ? "requested_tickets"
          : "requested_free_tickets");
      axios
        .post(req, payloadToPush)
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (vm.redeemData && vm.redeemData.price === 0)
          ) {
            // vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.ageRange = "21-29";
            vm.fullname = "";
            vm.email = "";
            vm.hearAboutSocial = "";
            vm.hearAboutLinkedIn = "";
            vm.hearAbout = "";
            vm.foodAllergy = "";
            vm.isSubmitted = false;
            setTimeout(() => {
              this.showsuccess = true;
              this.$refs.confirmmodal.open();
              localStorage.removeItem("refsId");
            }, 1000);
          }

          setTimeout(() => {
            vm.isSubmitted = false;
          }, 60000);
          vm.reserveFormAccept = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
        });
    },
    fetchAllTickets: function () {
      const vm = this;
      this.isSubmitted = true;
      vm.activeWaitList = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      const payloadToPush = {
        eventId: "SE6DsRyAoimkY6",
      };
      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/fetchSponsoredEventTicketsWithSeating",
          payloadToPush
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            ticketsResult.data.Result.forEach((e) => {
              if (e.activeWaitList) {
                vm.activeWaitList = true;
              }
            });
            vm.TicketsResult = ticketsResult.data.Result;
          }

          const objtmp = {};

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr;
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  mounted() {
    const isScrolledIntoView = (el) => {
      var top = el.offsetTop;
      var left = el.offsetLeft;
      var width = el.offsetWidth;
      var height = el.offsetHeight;

      while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
      }

      return (
        top < window.pageYOffset + window.innerHeight &&
        left < window.pageXOffset + window.innerWidth &&
        top + height > window.pageYOffset &&
        left + width > window.pageXOffset
      );
    };

    window.addEventListener("scroll", function () {
      console.log("========");
      let container = document.querySelector("#description");
      if (isScrolledIntoView(container)) {
        container.classList.add("inView");
      }

      container = document.querySelector("#imagesection");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromside");
      }

      container = document.querySelector("#anight");
      if (isScrolledIntoView(container)) {
        container.classList.add("inView");
      }
      container = document.querySelector("#adress");
      if (isScrolledIntoView(container)) {
        container.classList.add("inView");
      }

      container = document.querySelector("#adown");
      if (isScrolledIntoView(container)) {
        container.classList.add("inView");
      }

      container = document.querySelector("#imagesection1");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromsideo");
      }
      container = document.querySelector("#imagesectio2");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromside");
      }
    });

    this.fetchAllTickets();
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    valCheck() {
      return (
        !this.reserveFormAccept ||
        !this.fullname.trim().length ||
        !this.email.trim().length ||
        !this.invalidemail
      );
    },
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title:
      "MYLE | Jahri Evans Saints and Stars Big Game Weekend Masquerade Mixer",
    meta: [
      {
        name: "description",
        content:
          "MYLE | Jahri Evans Saints and Stars Big Game Weekend Masquerade Mixer",
      },

      {
        name: "keywords",
        content:
          "MYLE | Jahri Evans Saints and Stars Big Game Weekend Masquerade Mixer",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content:
          "https://myle.com/jahri-evans-saints-and-stars-big-game-weekend-masquerade-mixer",
      },
    ],
  },
};
</script>

<style scoped>
.velmld-parent {
  background: #ffffff !important;
}

#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.cgreen {
  background: #0ea293;
  border: 1px solid #0ea293 !important;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}

.bolded {
  font-weight: bold;
  font-size: 1.2em;
}

@media (max-width: 768px) {
  #redeem {
    text-align: center;
  }
}

@media (max-width: 622px) {
  #d1 {
    overflow-x: scroll;
  }
}
@media (min-width: 622.1px) {
  #d1 {
    overflow-x: hidden;
  }
}

.shimmer {
  display: inline-block;
  mask: linear-gradient(-60deg, #000 30%, rgba(179, 255, 0, 0.333), #000 70%)
    right/350% 100%;
  animation: shimmer 2.5s infinite;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.fromside {
  animation-duration: 1s; /* the duration of the animation */
  animation-timing-function: ease-out; /* how the animation will behave */
  animation-delay: 0s; /* how long to delay the animation from starting */
  animation-iteration-count: 1; /* how many times the animation will play */
  animation-name: slideInFromLeft; /* the name of the animation we defined above */
}

@keyframes slideInFromLefto {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.fromsideo {
  animation-duration: 1s; /* the duration of the animation */
  animation-timing-function: ease-out; /* how the animation will behave */
  animation-delay: 0s; /* how long to delay the animation from starting */
  animation-iteration-count: 1; /* how many times the animation will play */
  animation-name: slideInFromLefto; /* the name of the animation we defined above */
}

@keyframes shimmer {
  100% {
    mask-position: left;
  }
}

@keyframes example {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0.1;
  }
  20% {
    opacity: 0.2;
  }
  30% {
    opacity: 0.3;
  }
  40% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.5;
  }
  70% {
    opacity: 0.7;
  }
  80% {
    opacity: 0.8;
  }
  90% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}

.inView {
  animation-name: example;
  animation-duration: 2.5s;
}
</style>
