<template>
  <div>
    <form
      style="visibility: hidden"
      method="post"
      :action="`${action}`"
      name="formAuthorizeNetTestPage"
    >
      <input type="text" name="token" v-model="actionValue" />
      <button id="btnContinue">OPEN CHECKOUT</button>
    </form>

    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted || loader"
      text="Loading"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <nu-modal ref="confirmmodal12">
      <successModal :showsuccess="showsuccess" @closemodal="closeModal" />
    </nu-modal>

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />

    <nu-modal ref="smodal" title="">
      <successModal @closemodal="closeModaldata()" />
    </nu-modal>

    <nu-modal ref="modalreg1" title="">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 mb-4">
            <div class="text-center">
              <h3
                style="
                  font-family: 'RegulatorNova-Bold';
                  font-size: 2.8em;
                  font-weight: 700;
                  color: #000000;
                "
              >
                REGISTRATION
              </h3>
              <label
                class="mb-3"
                style="
                  font-family: 'RegulatorNova-Light';
                  font-size: 1.2em;
                  font-weight: 600;
                  color: #202c5d;
                "
                >Please complete the below fields to register.
              </label>
            </div>
          </div>

          <div class="col-md-6 mb-1">
            <label
              style="
                font-family: 'RegulatorNova-Bold';
                font-size: 1.2em;
                font-weight: 600;
                color: #000000;
              "
              >First Name</label
            >
            <input
              type="text"
              placeholder="Enter first name"
              class="form-control"
              v-model="userFirstName"
            />
            <span
              v-if="istouched && userFirstName.trim().length <= 0"
              class="mb-3"
              style="color: red; font-size: 12px"
              >*First name is required</span
            >
          </div>
          <div class="col-md-6 mb-1">
            <label
              style="
                font-family: 'RegulatorNova-Bold';
                font-size: 1.2em;
                font-weight: 600;
                color: #000000;
              "
              >Last Name</label
            >
            <input
              type="text"
              placeholder="Enter last name"
              class="form-control"
              v-model="userLastName"
            />
            <span
              v-if="istouched && userLastName.trim().length <= 0"
              class="mb-3"
              style="color: red; font-size: 12px"
              >*Last name is required</span
            >
          </div>
          <div class="col-md-6 mb-1 mt-4">
            <label
              style="
                font-family: 'RegulatorNova-Bold';
                font-size: 1.2em;
                font-weight: 600;
                color: #000000;
              "
              >Email</label
            >
            <input
              type="text"
              placeholder="Enter email address"
              class="form-control"
              v-model="usersEmail"
            />
            <span
              v-if="istouched && !invaliduemail"
              class="mb-3"
              style="color: red; font-size: 12px"
              >*Please provide valid email address</span
            >
          </div>
          <div class="col-md-6 mb-1 mt-4">
            <label
              class=""
              style="
                font-family: 'RegulatorNova-Bold';
                font-size: 1.2em;
                font-weight: 600;
                color: #000000;
              "
              >Phone
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter phone number"
              @keypress="isNumber()"
              v-model="userContactPhone"
            />
            <span
              v-if="istouched && !invaliduphone"
              class="mb-3"
              style="color: red; font-size: 12px"
              >*please fill valid phone number</span
            >
          </div>

          <div class="col-md-6 mb-1 mt-4" v-if="false">
            <label
              style="
                font-family: 'RegulatorNova-Bold';
                font-size: 1.2em;
                font-weight: 600;
                color: #000000;
              "
              >Are you bringing guest(s)?</label
            >
            <select
              class="form-control"
              v-model="guestComing"
              @change="
                () => {
                  emailList =
                    guestComing === 'Yes' ? [{ name: '', email: '' }] : [];
                }
              "
            >
              <option
                class="form-control"
                v-bind:key="count + 'b'"
                v-for="count in ['Yes', 'No']"
                :value="count"
              >
                {{ count }}
              </option>
            </select>
          </div>

          <div class="col-md-6 mb-1 mt-4" v-if="emailList.length > 0">
            <label
              style="
                font-family: 'RegulatorNova-Bold';
                font-size: 1.2em;
                font-weight: 600;
                color: #000000;
              "
              >Guests Count</label
            >
            <select
              class="form-control"
              v-model="guestCount"
              @change="createList"
            >
              <option
                class="form-control"
                v-bind:key="count + 'b'"
                v-for="count in [1, 2, 3, 4, 5]"
                :value="count"
              >
                {{ count }}
              </option>
            </select>
          </div>

          <div
            class="col-md-12 row p-0 ml-0"
            v-for="g in emailList"
            v-bind:key="g"
          >
            <div class="col-md-6 mb-1 mt-4">
              <label
                style="
                  font-family: 'RegulatorNova-Bold';
                  font-size: 1.2em;
                  font-weight: 600;
                  color: #000000;
                "
                >Guest Full Name
              </label>
              <input
                type="text"
                placeholder="Enter guest full name"
                class="form-control"
                v-model="g.name"
              />
              <span
                v-if="istouched && g.name.trim().length <= 0"
                class="mb-3"
                style="color: red; font-size: 12px"
                >*Guest full name is required</span
              >
            </div>

            <div class="col-md-6 mb-1 mt-4">
              <label
                style="
                  font-family: 'RegulatorNova-Bold';
                  font-size: 1.2em;
                  font-weight: 600;
                  color: #000000;
                "
                >Guest Email</label
              >
              <input
                type="text"
                placeholder="Enter guest email address"
                class="form-control"
                v-model="g.email"
              />
              <span
                v-if="istouched && !invalidgemail(g.email)"
                class="mb-3"
                style="color: red; font-size: 12px"
                >*Please provide valid guest email address</span
              >
            </div>
          </div>

          <div
            class="col-md-12 mt-5"
            style="
              font-family: 'Source Sans 3';
              font-size: 1.1em;
              font-weight: 500;
              color: black;
            "
          >
            <input type="checkbox" v-model="reserveFormAccept" />
            By Participating in this program, I agree to the
            <a
              style="
                color: #000000;
                cursor: pointer;
                text-decoration: underline !important;
              "
              target="_self"
              rel="noopener noreferrer"
              @click="openTermsModel()"
            >
              Terms & Conditions.</a
            >
            <br />
            <span
              v-if="istouched && !this.reserveFormAccept"
              class="mb-3"
              style="color: red; font-size: 12px"
              >*Please accept the terms & conditions</span
            >
          </div>

          <div class="col-md-12 mt-5 text-center">
            <button
              type="submit"
              @click="saveNewForm()"
              class="btn solid-btn-MarriottBonvoyHouseVisa m-auto mr-2"
              style="
                pointer-events: all;
                cursor: pointer;
                padding: 0em 0.7em;
                font-family: 'RegulatorNova-Light', sans-serif;
                font-size: 3em;
              "
            >
              REGISTER
            </button>
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- conifrm model -->
    <nu-modal ref="confirmmodal" title="">
      <confirmationPopup
        :showsuccess="showsuccess"
        @closemodal="closeModalerror"
      />
    </nu-modal>
    <!-- confrim model -->

    <!-- error model -->
    <nu-modal ref="emodal" title="">
      <errorModal @closemodal="closeModalerror" />
    </nu-modal>
    <!-- error model -->

    <nu-modal ref="termsModal" title="">
      <div class="container-fluid">
        <div class="row">
          <!-- Ticket Cart -->

          <div
            class="row align-items-center justify-content-between"
            style="margin: auto; width: 100% !important"
          >
            <div class="col-md-12">
              <div class="text-center">
                <span
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'RegulatorNova-Bold';
                    font-size: 2.4em;
                    font-weight: 700;
                    color: #000000;
                  "
                >
                  Terms & Conditions
                </span>
              </div>
            </div>
            <div class="col-lg-12 col-md-12">
              <div style="height: auto; overflow: auto">
                <p
                  class="mb-1 mt-4"
                  style="
                    font-family: 'RegulatorNova-Light';
                    font-weight: 700;
                    font-size: 1.1em;
                    color: #000000;
                  "
                >
                  I authorize Marriott International, Inc., its subsidiaries,
                  affiliates, partners, designees, officers, directors, agents
                  and employees (together, "Marriott") and Visa U.S.A. Inc, to
                  photograph, videotape and record me and otherwise preserve my
                  name, image, likeness, voice, biographical material, and oral
                  and written statements (together, “My Likeness”) and to use,
                  reproduce, display, distribute and publish works containing
                  any part of My Likeness (together, “the Materials”) throughout
                  the world in all and any media now known or hereafter devised,
                  including without limitation, in print, video, audio,
                  electronic and digital materials, in websites and on social
                  media platforms, for any lawful purpose whatsoever. I
                  acknowledge that Marriott is the sole owner of all rights in
                  and to the Materials, including all rights of copyright, for
                  all purposes, regardless of their form or medium. Marriott
                  shall have the right, among other things, to edit the
                  Materials at its discretion, to incorporate any part of My
                  Likeness in the Materials, to use, duplicate, exhibit,
                  display, broadcast and distribute the Materials and to license
                  others to do so in all media now known or hereafter devised. I
                  waive any right that I may have to inspect or approve any part
                  of the Materials that incorporate My Likeness. I release and
                  discharge Marriott and Visa U.S.A. Inc. from any and all
                  liability arising out of or relating to its use of My
                  Likeness, including without limitation claims based on breach
                  of confidence, right of privacy or right of publicity; on any
                  blurring, distortion, alteration, optical illusion, or use in
                  composite form, whether intentional or otherwise, that may
                  occur or be produced in the use of My Likeness; or on any
                  editing or alterations to the Materials. In consideration for
                  the rights I have granted to Marriott, I shall receive the
                  opportunity to have My Likeness appear in the Materials. I
                  acknowledge, however, that Marriott shall be under no
                  obligation to use any part of My Likeness. I further
                  acknowledge that I will not receive any monetary compensation
                  for any use of My Likeness and that I shall not be entitled to
                  receive any proceeds that may be derived from the Materials. I
                  consent to Marriott’s collection, storage, use and processing
                  of my personal information (such as My Likeness and my contact
                  details) for the purposes described above and in accordance
                  with the Marriott Group Global Privacy Statement (available
                  under https://www.marriott.com/about/privacy.mi or upon
                  request). I also consent to Marriott’s transfer of the
                  Materials and copies of My Likeness to any destination
                  throughout the world, including outside the European Economic
                  Area (“EEA”) for the purposes set forth above or in case it
                  needs to contact me, and I understand that these destinations
                  may have a different level of data protection compared to the
                  one in my country. Without limitation, I understand that
                  Marriott International, Inc.’s head office is located in the
                  United States of America (“USA”), and therefore the Materials
                  may be stored in or transferred to the USA; and that
                  Marriott’s subsidiaries, affiliates, partners, designees,
                  officers, directors, agents and employees may be located in
                  the USA or elsewhere, including outside the EEA. I understand
                  that I have the right to withdraw my consent at any time, but
                  that this will not affect the lawfulness of any processing
                  carried out before I withdraw my consent. Further information
                  regarding my rights and the processing of my personal
                  information can be found in the Privacy Statement. I warrant
                  that I am at least eighteen years of age, have every right to
                  contract in my own name in this matter, have read the Marriott
                  Group Global Privacy Statement as well as the above
                  authorization and release prior to its execution and am fully
                  aware of its contents. By signing below, affixing my
                  electronic signature and/or clicking the checkbox provided (as
                  applicable), I agree that such action constitutes a legal
                  signature and acknowledge that this document is legally
                  binding

                  <br /><br />

                  I agree to waive and release any and all claims against
                  Marriott, Visa U.S.A., Inc. Havoc Live, LLC dba 160 over 90,
                  each of their parents, affiliates, subsidiaries, agents, and
                  assigns, and each of their contractors and licensees relating
                  to my name, my likeness, the Media and their uses and/or
                  distribution in any version or media throughout the internet,
                  including without limitation, any rights and claims relating
                  to royalties or compensation, editing, alteration, copyright,
                  distribution, misappropriation, libel, false light, rights of
                  privacy and/or publicity.

                  <br /><br />

                  Event: Marriott Bonvoy House Presented by Visa Super Bowl LIX
                  Party

                  <br />

                  Date: Friday February 7, 2025

                  <br />

                  Time: 8pm - 12am

                  <br />

                  Location: House of Blues, New Orleans LA
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nu-modal>

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar :allowButton="allowButton" />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center" style="margin-top: 40px">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_MarriottBonvoyHouseVisa_V1.png"
            style="width: 100%; max-width: 100%; height: auto"
          />
        </div>
      </div>
      <!-- banner section ends -->
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between lessSideMargin"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="row mt-2">
              <div class="col-md-12 col-md-12">
                <div class="download-content mt-4 justify-content-center">
                  <h1
                    class="text-center"
                    style="
                      font-family: 'RegulatorNova-Light';
                      font-weight: 700;
                      font-size: 1.55em;
                      color: #000000;
                    "
                  >
                    We invite you to a night like no other at Marriott Bonvoy
                    House, presented by Visa, where New Orleans’ finest come to
                    celebrate Super Bowl LIX. Sip, savor, and sway to the sounds
                    of live soul in a space curated just for the VIPs of the Big
                    Easy. Here, we don't just watch the magic — we're part of
                    it. King cake, beignets... why choose?
                  </h1>

                  <div class="row mt-5">
                    <div class="col-md-4 col-md-4">
                      <p
                        class="text-center"
                        style="
                          font-family: 'RegulatorNova-Bold', sans-serif;
                          font-size: 1.6em;
                          color: rgb(34, 33, 33);
                        "
                      >
                        <img
                          src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/icon_calendar_MarriottBonvoyHouseVisa_V1.svg"
                          style="border-radius: 3%; width: 25%"
                        />
                        <br />
                        <span
                          >Friday,
                          <br />
                          February 7, 2025</span
                        >
                      </p>
                    </div>
                    <div class="col-md-4 col-md-4">
                      <p
                        class="text-center"
                        style="
                          font-family: 'RegulatorNova-Bold', sans-serif;
                          font-size: 1.6em;
                          color: rgb(34, 33, 33);
                        "
                      >
                        <img
                          src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/icon_clock_MarriottBonvoyHouseVisa_V1.svg"
                          style="border-radius: 3%; width: 25%"
                        />
                        <br />
                        <span
                          >8:00 PM – 12:00 AM
                          <br />
                          Central Time
                        </span>
                      </p>
                    </div>
                    <div class="col-md-4 col-md-4">
                      <p
                        class="text-center"
                        style="
                          font-family: 'RegulatorNova-Bold', sans-serif;
                          font-size: 1.6em;
                          color: rgb(34, 33, 33);
                        "
                      >
                        <img
                          src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/icon_dress_MarriottBonvoyHouseVisa_V1.svg"
                          style="border-radius: 3%; width: 25%"
                        />
                        <br />
                        <span
                          >Cocktail attire
                          <br />
                          inspired by 1920s.</span
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-md-12">
                <div class="mt-2">
                  <div
                    class="row text-center align-items-center justify-content-between"
                  >
                    <div class="col-lg-12 col-md-12">
                      <div class="align-items-center justify-content-between">
                        <div
                          class="align-items-center justify-content-between mt-3 mb-3"
                        >
                          <button
                            class="btn solid-btn-MarriottBonvoyHouseVisa align-items-center justify-content-between"
                            @click="opencartm()"
                            style="
                              pointer-events: all;
                              cursor: pointer;
                              font-family: 'RegulatorNova-Light', sans-serif;
                              font-size: 4em;
                              background-image: url(https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_btn_bg_MarriottBonvoyHouseVisa_V1.png)
                                repeat;
                              padding: 0.01em 1.7em;
                            "
                          >
                            {{ "RSVP" }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row w-100 mt-5 mb-5 m-0 p-0" style="background: #2c1323">
        <div class="col-lg-12 col-md-12 p-0">
          <div class="mt-0 mb-0 ml-0 mr-0 p-0">
            <div class="text-center p-0 mt-0 mb-0">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_venue_MarriottBonvoyHouseVisa_V1.png"
                style="width: 80%"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        class="row align-items-center justify-content-between lessSideMargin"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="">
            <p
              class="w-auto d-inline-block mb-2"
              style="
                font-family: 'RegulatorNova-Bold', sans-serif;
                font-size: 2.5em;
                color: #000000;
              "
            >
              HOUSE OF BLUES
            </p>
          </div>

          <div class="row mt-2">
            <div
              class="col-md-7 col-md-7"
              style="font-family: 'Arial', sans-serif"
            >
              <p
                class=""
                style="
                  font-family: 'RegulatorNova-Light';
                  font-weight: 700;
                  font-size: 1.55em;
                  color: #000000;
                "
              >
                Step into the House of Blues, where the creativity of New
                Orleans comes together to create an unforgettable experience.
                Immerse yourself in live performances that echo the spirit of
                jazz icons, wander through an art collection that tells tales of
                the region’s heritage, and savor the energy of a space designed
                to inspire. House of Blues is more than a stop in NOLA—it’s a
                journey you’ll never forget.
              </p>

              <div class="row">
                <div class="col-md-2 col-4">
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/icon_location_MarriottBonvoyHouseVisa_V1.svg"
                    style="width: 100%"
                  />
                </div>
                <div class="col-md-10 col-8">
                  <p
                    class=""
                    style="
                      font-family: 'RegulatorNova-Bold', sans-serif;
                      font-size: 1.4em;
                      color: #000000;
                    "
                  >
                    Address:
                    <br />
                    <a
                      href="https://maps.app.goo.gl/BRy1ZVsnX43qQdZQ6"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="color: #000000"
                    >
                      House of Blues, 225 Decatur St,
                      <br />
                      New Orleans, LA 70130
                    </a>
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-5 col-md-5">
              <div class="text-center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_venue_MarriottBonvoyHouseVisa_V1.jpg"
                  class="w-100 img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  </div>
</template>

<script>
// Component Imports

import NavBar from "../../../views/commons/Navbar9";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import confirmationPopup from "../../../components/confirmationPopup";
import errorModal from "../Error/Error";
import successModal from "../Success/Success.vue";
import VueElementLoading from "vue-element-loading";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      emailList: [],
      guestCount: 0,
      allowButton: true,
      guestComing: "No",
      guestEmail: "",
      istouched: false,
      userFirstName: "",
      userLastName: "",
      usersEmail: "",
      userContactPhone: "",
      loader: false,
      action: "",
      actionValue: "",
      vcesId: null,
      ismounted: false,
      iframeSrc: "https://google.com",
      firstName: "",
      lastName: "",
      address: "",
      names: {},
      showsuccess: false,
      section1: ["tk_EdA4v6IBC0mEpMV"],
      redeempromo: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        tk_PmKuRNH6Hb4CTDw: {
          messages: [],
        },
      },
      priceIds: {
        tk_EdA4v6IBC0mEpMV: "price_1QU1DOD0vTZ4QB9cPyua545V",
      },
      eventTicketResult: [],
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
    };
  },
  components: {
    NavBar,
    confirmationPopup,
    VueElementLoading,
    StripeCheckout,
    successModal,
    errorModal,
  },
  methods: {
    invalidgemail(e) {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(e);
    },
    createList() {
      this.emailList = [];
      for (let i = 0; i < this.guestCount; i++) {
        this.emailList.push({
          email: "",
          name: "",
        });
      }
    },
    closeModaldata() {
      this.$refs.modalreg1.close();
      this.$refs.smodal.close();
    },
    saveNewForm() {
      console.log("wqwqwq");
      this.istouched = true;
      if (this.valCheck || !this.reserveFormAccept || this.checkguest) {
        return;
      }

      this.isSubmitted = true;
      const vm = this;
      const liste = [];

      this.emailList.forEach((r) => {
        liste.push({
          guestname: r.name,
          guestemail: r.email,
        });
      });

      const payloadData = {
        userName: this.userFirstName + " " + this.userLastName,
        userEmail: this.usersEmail.toLowerCase(),
        userId: "",
        eventId: "mFeqLRM90cWXTn",
        origin: "WEB",
        tickets: [],
      };

      var tickObj = {
        priceId: "price_1QU1DOD0vTZ4QB9cPyua545V",
        ticketId: "tk_EdA4v6IBC0mEpMV",
        totalTickets: 1,
        additionalInfo: {},
        extraInfo: {
          phone: this.userContactPhone,
          guestComing: this.guestComing,
          guest: liste,
        },
      };

      payloadData.tickets = [tickObj];
      vm.isSubmitted = true;

      axios
        .post("https://api.myle.com/api/request_cart_tickets_v3", payloadData)
        .then((re) => {
          vm.action = "";
          vm.actionValue = "";
          if (re.data && re.data.Message) {
            this.showsuccess = true;
            this.$refs.modalreg1.close();
            this.$refs.confirmmodal12.open();
          }
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
        })
        .finally(() => {
          vm.isSubmitted = false;
        });
    },
    opencartfalse() {},
    getstatus() {
      this.isSubmitted = true;
      axios({
        method: "post",
        url: "https://api.myle.com/api/vces_verify_card_eligibility",
        data: {
          vcesId: this.vcesId,
        },
      })
        .then((resp) => {
          if (resp && resp.data && resp.data.Result) {
            if (resp.data.Result.eligible) {
              this.opencartm();
            } else {
              this.opencartfalse();
            }
          } else {
            this.opencartfalse();
          }
          console.log(resp);
        })
        .catch(() => {
          this.opencartfalse();
        })
        .finally(() => {
          this.isSubmitted = false;
        });
    },
    closeModalerror() {
      this.$refs.emodal.close();
    },
    onLoad() {
      if (this.ismounted) {
        setTimeout(() => {
          this.isSubmitted = false;
        }, 0);
      }
    },
    opencartm() {
      this.openRegisterModal();
    },
    changeFavicon(url) {
      console.log("warda", url);
      var link = document.querySelectorAll("link[rel~='icon']");
      if (link && link.length) {
        link.forEach((r) => {
          r.remove();
        });
      }
      link = null;
      var applink = document.querySelectorAll("link[rel~='apple-touch-icon']");
      if (applink && applink.length) {
        applink.forEach((r) => {
          r.remove();
        });
      }
      applink = null;

      var mapplink = document.querySelectorAll("link[rel~='mask-icon']");
      if (mapplink && mapplink.length) {
        mapplink.forEach((r) => {
          r.remove();
        });
      }

      mapplink = null;

      setTimeout(() => {
        const mapplink1 = document.createElement("link");
        mapplink1.rel = "mask-icon";
        mapplink1.color = "#5bbad5";
        mapplink1.href = url + "safari-pinned-tab.svg?v=2";
        document.head.appendChild(mapplink1);

        const applink1 = document.createElement("link");
        applink1.rel = "apple-touch-icon";
        applink1.sizes = "180x180";
        applink1.href = url + "apple-touch-icon.png?v=2";
        document.head.appendChild(applink1);

        const applink12 = document.createElement("link");
        applink12.rel = "apple-touch-icon";
        applink12.sizes = "152x152";
        applink12.href = url + "apple-touch-icon.png?v=2";
        document.head.appendChild(applink12);

        const link1 = document.createElement("link");
        link1.rel = "icon";
        link1.sizes = "16x16";
        link1.href = url + "favicon-16x16.png?v=2";
        document.head.appendChild(link1);

        const link96 = document.createElement("link");
        link96.rel = "icon";
        link96.sizes = "96x96";
        link96.href = url + "favicon-96x96.png?v=2";
        document.head.appendChild(link96);

        const link192 = document.createElement("link");
        link192.rel = "icon";
        link192.sizes = "192x192";
        link192.href = url + "android-icon-192x192.png?v=2";
        document.head.appendChild(link192);

        const link180 = document.createElement("link");
        link180.rel = "apple-touch-icon";
        link180.sizes = "180x180";
        link180.href = url + "apple-icon-180x180.png?v=2";
        document.head.appendChild(link180);

        const link152 = document.createElement("link");
        link152.rel = "apple-touch-icon";
        link152.sizes = "152x152";
        link152.href = url + "apple-icon-152x152.png?v=2";
        document.head.appendChild(link152);

        const link144 = document.createElement("link");
        link144.rel = "apple-touch-icon";
        link144.sizes = "144x144";
        link144.href = url + "apple-icon-144x144.png?v=2";
        document.head.appendChild(link144);

        const link120 = document.createElement("link");
        link120.rel = "apple-touch-icon";
        link120.sizes = "120x120";
        link120.href = url + "apple-icon-120x120.png?v=2";
        document.head.appendChild(link120);

        const link114 = document.createElement("link");
        link114.rel = "apple-touch-icon";
        link114.sizes = "114x114";
        link114.href = url + "apple-icon-114x114.png?v=2";
        document.head.appendChild(link114);

        const link76 = document.createElement("link");
        link76.rel = "apple-touch-icon";
        link76.sizes = "76x76";
        link76.href = url + "apple-icon-76x76.png?v=2";
        document.head.appendChild(link76);

        const link72 = document.createElement("link");
        link72.rel = "apple-touch-icon";
        link72.sizes = "72x72";
        link72.href = url + "apple-icon-72x72.png?v=2";
        document.head.appendChild(link72);

        const link60 = document.createElement("link");
        link60.rel = "apple-touch-icon";
        link60.sizes = "60x60";
        link60.href = url + "apple-icon-60x60.png?v=2";
        document.head.appendChild(link60);

        const link57 = document.createElement("link");
        link57.rel = "apple-touch-icon";
        link57.sizes = "57x57";
        link57.href = url + "apple-icon-57x57.png?v=2";
        document.head.appendChild(link57);

        const link2 = document.createElement("link");
        link2.rel = "icon";
        link2.sizes = "32x32";
        link2.href = url + "favicon-32x32.png?v=2";
        document.head.appendChild(link2);

        const link3 = document.createElement("link");
        link3.rel = "icon";
        link3.href = url + "favicon.ico?v=2";
        document.head.appendChild(link3);
      }, 300);
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    opencart() {
      this.openRegisterModal();
    },
    openRegisterModal() {
      this.guestComing = "No";
      this.guestCount = 0;
      this.emailList = [];
      this.guestFirstName = "";
      this.guestLastName = "";
      this.guestEmail = "";
      this.guestContactPhone = "";
      this.istouched = false;
      this.userFirstName = "";
      this.userLastName = "";
      this.usersEmail = "";
      this.userContactPhone = "";
      this.istouched = false;
      this.$refs.modalreg1.open();
    },
    invalidNum(n) {
      return (n || "").match(/\d/g) && (n || "").trim().length >= 10;
    },
    checkInvalidEmail(e) {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(e);
    },
    section1Filter(list) {
      return list.filter((l) => this.section1.includes(l.ticketCode));
    },
    closeModal() {
      this.showsuccess = false;
      this.$refs.confirmmodal12.close();
    },
    openTermsModel: function () {
      this.$refs.termsModal.open();
    },
  },
  mounted() {
    if (
      this.$route &&
      this.$route.params &&
      this.$route.params.checkout == "checkout" &&
      this.$route.params.status == "success"
    ) {
      this.showsuccess = true;
      setTimeout(() => {
        this.$refs.confirmmodal12.open();
      }, 1000);
      localStorage.removeItem("refsId");
    } else if (
      this.$route &&
      this.$route.params.checkout == "checkout" &&
      this.$route.params.status == "failed"
    ) {
      this.showsuccess = false;
      setTimeout(() => {
        this.$refs.emodal.open();
      }, 1000);
      localStorage.removeItem("refsId");
    }

    setTimeout(() => {
      this.ismounted = true;
    }, 0);
  },
  created() {
    document.addEventListener("eventverify", () => {
      this.getstatus();
    });
    const r = window.location.origin;
    this.changeFavicon(r + "/img/icons8/");
  },
  computed: {
    checkguest() {
      const reg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (this.emailList.length > 0) {
        return (
          this.emailList.filter(
            (r) => r.name.trim().length <= 0 || !reg.test(r.email.trim())
          ).length > 0
        );
      }
      return false;
    },
    valCheck() {
      return (
        !this.reserveFormAccept ||
        !this.userFirstName.trim().length ||
        !this.userLastName.trim().length ||
        !this.usersEmail.trim().length ||
        !this.userContactPhone.trim().length ||
        !this.invaliduphone ||
        !this.invalidZip ||
        !this.invaliduemail
      );
    },
    invalidZip() {
      return (this.zip || "").match(/^[0-9]*$/g);
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
    invaliduemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.usersEmail
      );
    },
    invaliduphone() {
      if (this.userContactPhone.length <= 0) {
        return false;
      }
      return (
        (this.userContactPhone || "").match(/\d/g) &&
        (this.userContactPhone || "").trim().length >= 10
      );
    },
  },
  metaInfo: {
    title: "Marriott Bonvoy House '25",

    meta: [
      {
        name: "description",
        content: "Marriott Bonvoy House '25",
      },

      {
        name: "keywords",
        content: "Marriott Bonvoy House '25",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content:
          "https://myle.com/marriott-bonvoy-house-25-for-marriott/:checkout?/:status?",
      },
    ],
  },
};
</script>

<style scoped>
.background-overlay {
  background: #2c132391 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}
.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}
.bolded {
  font-weight: bold;
}
.nu-modal.has-content.is-visible {
  max-width: 600px !important;
}
</style>
